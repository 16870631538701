import { Injectable } from '@angular/core';
import { FeatureFlags } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureAvailabilityService {
  featureFlags: any = {
    mivoicebusiness: false
  };

  checkMiVBFeatureFlag(): boolean {
    return this.featureFlags.mivoicebusiness;
  }

  // LD flags
  initFeatureFlagsFromParent(featureFlags: any[]) {
    console.log(`Received feature flags from parent ${featureFlags}`);
    if (featureFlags) {
      let mivbFeature = featureFlags.find(f => f.feature == FeatureFlags.ALLOW_MIVOICE_BUSINESS);
      this.featureFlags.mivoicebusiness = !!(mivbFeature && mivbFeature.enabled);
    }
  }
}
