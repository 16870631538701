export const MAX_ERROR = 50;

export const dashboardTypes = {
	PARTNER: 'Partner',
	ACCOUNT: 'Account',
};

export const listItemTypes = {
	ACCOUNT: 'customer',
	SITE: 'gateway',
};

export const connectStates = {
	CAN_CONNECT: 'Can Connect',
	CANNOT_CONNECT: 'Cannot Connect',
	CONNECTING: 'Connecting',
	CONNECT_SUCCESS: 'Connect Success',
	CONNECT_FAILED: 'Connect Failed',
	CONNECT_RESULT: 'Connect Result',
};

export const commonMessages = {
  ACC_NOT_FOUND: 'common.messages.ACC_NOT_FOUND',
  NOT_VALID_EMAIL: 'common.messages.NOT_VALID_EMAIL',
  ENTER_ACC: 'common.messages.ENTER_ACC',
  CHECK_FIELDS: 'common.messages.CHECK_FIELDS',
  CHECK_DEVICE: 'common.messages.CHECK_DEVICE',
  ERROR_DEFAULT: 'common.messages.ERROR_DEFAULT',
  ERR_PROGRESS: 'common.messages.ERR_PROGRESS',
  ERR_PBX_LOGIN: 'common.messages.ERR_PBX_LOGIN',
  ERR_PBX_INV_PW: 'common.messages.ERR_PBX_INV_PW',
  AUTH_ERROR_MSG: 'common.messages.AUTH_ERROR_MSG',
  TYPE_ERROR_MSG: 'common.messages.TYPE_ERROR_MSG',
  TUNNEL_AUTH_ERR: 'common.messages.TUNNEL_AUTH_ERR',
  ERR_SERVICE_UNAVAILABLE: "common.messages.ERR_SERVICE_UNAVAILABLE",
  ERR_SERVICE_UNAVAILABLE_RETRY: "common.messages.ERR_SERVICE_UNAVAILABLE_RETRY"
};

export const commonErrorNames = {
	MULTI_MATCH: 'MultipleMatching',
	ACC_NOT_FOUND: 'AccountNotFound',
	USER_NOT_FOUND: 'UserNotFound',
	INVALID_EMAIL: 'InvalidEmail',
	USER_EXISTS: 'UserAlreadyExists',
	MOBILE_EXISTS: 'MobileAlreadyExists',
	WORK_CONFLICT : 'WorkDomainConflict'
};

export const commonErrorMessages = {
	SRV_ERR_PBX_LOGIN : 'pbxLogin error - login timeout',
	SRV_ERR_INV_PW: 'pbxLogin error- INVALID PASSWORD',
	AUTH_ERROR: 'No token passed and no token could be found in localstorage. Please login and / or pass a valid token',
	UNSUPP_TYPE_MSG: 'Unsupported Media Type',
  TYPE_ERROR: 'Failed to fetch'
};

export const loginMessages = {
  LOG_IN: 'loginStatus.LOG_IN',
  LOGIN_ERROR: 'loginStatus.LOGIN_ERROR',
  ROLE_INVALID: 'loginStatus.ROLE_INVALID'
};

export const forgotPasswordMessages = {
	SEND_REQUEST: 'forgotPasswordStatus.SEND_REQUEST',
	ENTER_INFO: 'forgotPasswordStatus.ENTER_INFO',
	RESET_PASSWORD: 'forgotPasswordStatus.RESET_PASSWORD'
};

export const verifyStates = {
	NOT_VERIFIED: 'not verified',
	VERIFIED: 'verified',
	VERIFY_FAILED: 'verify failed',
};

export const toastMsgTypes = {
	INFO: 'info',
	ERROR: 'error',
};

export const accountMessages = {
	CREATE_ACCOUNT: 'account.CREATE_ACCOUNT',
	UPDATE_ACCOUNT: 'account.UPDATE_ACCOUNT',
	ERR_DOMAIN: 'account.ERR_DOMAIN',
	ERR_CREATE_ACCOUNT: 'account.ERR_CREATE_ACCOUNT',
	ERR_CREATE_ADMIN: 'account.ERR_CREATE_ADMIN',
	ERR_UPDATE_ACCOUNT: 'account.ERR_UPDATE_ACCOUNT',
	ERR_UPDATE_ACOUNT: 'account.ERR_UPDATE_ACCOUNT'
};

export const siteMessages = {
	ERR_TUNN_TOAST : 'site.ERR_TUNN_TOAST',
	ERR_TUNN: 'site.ERR_TUNN',
	ERR_TUNN_OLD: 'site.ERR_TUNN_OLD',
	ERR_GATEWAY: 'site.ERR_GATEWAY',
	ERR_SITE: 'site.ERR_SITE',
	UPDATE_SITE: 'site.UPDATE_SITE',
	ERR_UPDATE_SITE: 'site.ERR_UPDATE_SITE',
	INVALID_VCC_DID_NUMBER: 'site.INVALID_VCC_DID_NUMBER',
	CREATED_SITE: 'site.CREATED_SITE',
	ERR_CREATE_SITE: 'site.ERR_CREATE_SITE',
	ERR_CLIENT: 'site.ERR_CLIENT',
	ERR_GET_NETWORK: 'site.ERR_GET_NETWORK',
	ERR_SET_NETWORK: 'site.ERR_SET_NETWORK',
	ERR_SET_NETWORK_TIMEOUT: 'site.ERR_SET_NETWORK_TIMEOUT',
	CREATE_SITE: 'site.CREATE_SITE',
	UPDATING_SITE: 'site.UPDATING_SITE',
	CHECK_GATEWAY: 'site.CHECK_GATEWAY',
	SET_GATEWAY: 'site.SET_GATEWAY',
	CREATE_TUNNEL: 'site.CREATE_TUNNEL',
	UPDATE_TUNNEL: 'site.UPDATE_TUNNEL',
	CHECK_TUNNEL: 'site.CHECK_TUNNEL',
	UPDATE_NETWORK: 'site.UPDATE_NETWORK',
	CONNECTING_GATEWAY: 'site.CONNECTING_GATEWAY',
};

export const pbxMessages = {
	CREATE_PBX: 'pbx.CREATE_PBX',
	UPDATE_PBX: 'pbx.UPDATE_PBX',
	ERR_CREATE_PBX: 'pbx.ERR_CREATE_PBX',
	ERR_UPDATE_PBX: 'pbx.ERR_UPDATE_PBX',
	ERR_UPDATE_PBX_SERVER: 'pbx.ERR_UPDATE_PBX_SERVER',
	ERR_UPDATE_PBX_TIMEOUT: 'pbx.ERR_UPDATE_PBX_TIMEOUT',
	ERR_SIP_TRUNK_CONFIG: 'pbx.ERR_SIP_TRUNK_CONFIG',
	ERR_SIP_TRUNK_CONFIG_TOAST: 'pbx.ERR_SIP_TRUNK_CONFIG_TOAST',
	ERR_NO_TRUNKS: 'pbx.ERR_NO_TRUNKS',
	PBX_TRUNK_NOT_REGISTER: 'pbx.PBX_TRUNK_NOT_REGISTER',
	ERR_TRUNK_LICENSE: 'pbx.ERR_TRUNK_LICENSE',
	CONFIG_PBX: 'pbx.CONFIG_PBX',
	CONFIG_FREESWITCH: 'pbx.CONFIG_FREESWITCH',
	WAIT_FREESWTICH: 'pbx.WAIT_FREESWTICH',
	CANNOT_CONNECT_PBX: 'pbx.CANNOT_CONNECT_PBX',
	SYSTEM_DATA_TIMEOUT: 'pbx.SYSTEM_DATA_TIMEOUT',
	MBG_IP_INVALID: 'pbx.MBG_IP_INVALID',
	MBG_FAILURE_TO_AUTHORIZE: 'pbx.MBG_FAILURE_TO_AUTHORIZE',
	MICOLLAB_IP_INVALID: 'pbx.MICOLLAB_IP_INVALID',
	MICOLLAB_FAILURE_TO_AUTHORIZE: 'pbx.MICOLLAB_FAILURE_TO_AUTHORIZE',
	MICOLLAB_FAILURE_TO_INITIATE: 'pbx.MICOLLAB_FAILURE_TO_INITIATE',
	ERR_GET_PBX: 'pbx.ERR_GET_PBX',
	ERR_CONNECT_MEDIA: 'pbx.ERR_CONNECT_MEDIA',
  ERR_SERVER_UNAVAILABLE: 'serverUnavailableModal.title',
  GET_PBXLINKS: 'pbx.GET_PBXLINKS',
  UPLOAD_CERTIFICATE: 'pbx.UPLOAD_CERTIFICATE',
  ERR_UPLOAD_CERTIFICATE: 'pbx.ERR_UPLOAD_CERTIFICATE',
  REMOVE_CERTIFICATE: 'pbx.REMOVE_CERTIFICATE',
  ERR_REMOVE_CERTIFICATE: 'pbx.ERR_REMOVE_CERTIFICATE',
  ERR_GET_CERTIFICATE: 'pbx.ERR_GET_CERTIFICATE',
  ERR_INSTALL_CERTIFICATE_FILE_NOT_UPLOADED: 'pbx.ERR_INSTALL_CERTIFICATE_FILE_NOT_UPLOADED',
  MISSING_PBX_LINK: 'pbx.MISSING_PBX_LINK'
};

export const connectMessages = {
	ERROR_SYNC: 'connect.ERROR_SYNC',
	SYNC_COMPLETE: 'connect.SYNC_COMPLETE',
	UPDATE_SYNC_SCHEDULE: 'connect.UPDATE_SYNC_SCHEDULE',
	ERR_SYNC_SCHEDULE: 'connect.ERR_SYNC_SCHEDULE',
	INV_PSWD: 'connect.INV_PSWD',
	NO_CONNECTION: 'connect.NO_CONNECTION',
	CONNECT_PBX: 'connect.CONNECT_PBX',
	PBX_SYNC: 'connect.PBX_SYNC',
	PBX_RESYNC: 'connect.PBX_RESYNC',
	ERROR_RESYNC: 'connect.ERROR_RESYNC',
	GET_PBX_USERS: 'connect.GET_PBX_USERS',
	GET_CLOUDLINK_USERS: 'connect.GET_CLOUDLINK_USERS',
	UPDATE_USERS: 'connect.UPDATE_USERS',
	ADD_USERS: 'connect.ADD_USERS',
	REMOVE_USERS: 'connect.REMOVE_USERS',
	UPDATE_HUNT_GROUPS: 'connect.UPDATE_HUNT_GROUPS',
	ADD_HUNT_GROUPS: 'connect.ADD_HUNT_GROUPS',
	REMOVE_HUNT_GROUPS: 'connect.REMOVE_HUNT_GROUPS',
	UPDATE_PHANTOMS: 'connect.UPDATE_PHANTOMS',
	ADD_PHANTOMS: 'connect.ADD_PHANTOMS',
	REMOVE_PHANTOMS: 'connect.REMOVE_PHANTOMS',
	SYNC_USERS: 'connect.SYNC_USERS',
	SYNC_HUNT_GROUPS: 'connect.SYNC_HUNT_GROUPS',
	SYNC_PHANTOMS: 'connect.SYNC_PHANTOMS',
	GET_PBX_CONTACTS: 'connect.GET_PBX_CONTACTS',
	GET_CLOUDLINK_CONTACTS: 'connect.GET_CLOUDLINK_CONTACTS',
	UPDATE_CONTACTS: 'connect.UPDATE_CONTACTS',
	ADD_CONTACTS: 'connect.ADD_CONTACTS',
	REMOVE_CONTACTS: 'connect.REMOVE_CONTACTS',
};

export const resyncMessages = {
	ERROR_SYNC: 'resync.ERROR_SYNC',
	SYNC_COMPLETE: 'resync.SYNC_COMPLETE',
	TYPE_ERROR: 'resync.TYPE_ERROR',
	SYNC_COMPLETE_WITH_FAILURES: 'resync.SYNC_COMPLETE_WITH_FAILURES',
};

export const officeLinkMessages = {
	ERR_GET_GROUPS: 'officelink.ERR_GET_GROUPS',
	UPDATE_KEY_LINES: 'officelink.UPDATE_KEY_LINES',
	ERR_UPDATE_KEY_LINE: 'officelink.ERR_UPDATE_KEY_LINE',
	SEND_EMAIL_ALL: 'officelink.SEND_EMAIL_ALL',
	SEND_EMAIL_ADMIN: 'officelink.SEND_EMAIL_ADMIN',
	ERR_SEND_EMAIL: 'officelink.ERR_SEND_EMAIL',
	ERR_RETRIEVE_USERS: 'officelink.ERR_RETRIEVE_USERS',
	ERR_RETRIEVE_GROUPS: 'officelink.ERR_RETRIEVE_GROUPS'
};

export const advancedMessages = {
	UPDATE_ADVANCED: 'advanced.UPDATE_ADVANCED',
	ERROR_ADVANCED: 'advanced.ERROR_ADVANCED',
	DOWNLOAD_LOG: 'advanced.DOWNLOAD_LOG',
	SEND_PROBLEM: 'advanced.SEND_PROBLEM',
	ALARM_HISTORY: 'advanced.ALARM_HISTORY',
	USAGE_REPORT: 'advanced.USAGE_REPORT',
	TRACE_CALLS: 'advanced.TRACE_CALLS',
	ERR_GET_NETWORK: 'advanced.ERR_GET_NETWORK',
	ERR_SET_NETWORK: 'advanced.ERR_SET_NETWORK',
	ERR_SEND_TRACE: 'advanced.ERR_SEND_TRACE',
	COLLECT_LOGS: 'advanced.COLLECT_LOGS',
	ERR_COLLECT_LOGS: 'advanced.ERR_COLLECT_LOGS',
	ERR_LOG_TIMEOUT: 'advanced.ERR_LOG_TIMEOUT',
	UPLOAD_LOGS: 'advanced.UPLOAD_LOGS',
	ERR_UPLOAD_LOGS: 'advanced.ERR_UPLOAD_LOGS',
	LOGS_COMPLETE: 'advanced.LOGS_COMPLETE',
	ERR_UPLOAD_TIMEOUT: 'advanced.ERR_UPLOAD_TIMEOUT',
	FACTORY_RESET: 'advanced.FACTORY_RESET',
	UPDATE: 'advanced.UPDATE',
	UPDATE_SYSTEM: 'advanced.UPDATE_SYSTEM',
	UPDATE_SUCCESS: 'advanced.UPDATE_SUCCESS',
	REBOOT: 'advanced.REBOOT',
	REBOOT_SYSTEM: 'advanced.REBOOT_SYSTEM',
	REBOOT_SUCCESS: 'advanced.REBOOT_SUCCESS',
	RESTART: 'advanced.RESTART',
	RESTART_SYSTEM: 'advanced.RESTART_SYSTEM',
	RESTART_SUCCESS: 'advanced.RESTART_SUCCESS',
	CHECK_PBX: 'advanced.CHECK_PBX'
};

export const gatewayConnectState = {
	CONNECT_FAIL: 'connect fail',
	RETRY_SUCCESS: 'retry success',
	RETRY_FAIL: 'retry fail',
	LINK_FAIL: 'link fail',
};

export const linkingSteps = {
	STEP_1: 'STEP_1',
	STEP_2: 'STEP_2',
	STEP_3: 'STEP_3',
	STEP_4: 'STEP_4',
	STEP_5: 'STEP_5',
}

export const gatewayConnectStateMessages = {
	LOST_GATEWAY_CONNECTION: 'gatewayConnect.messages.LOST_GATEWAY_CONNECTION',
	LOST_OAI_CONNECTION: 'gatewayConnect.messages.LOST_OAI_CONNECTION',
	NOT_START_PBX_CONNECTION: 'gatewayConnect.messages.NOT_START_PBX_CONNECTION',
	INVALID_TRUSTED_APP_PASSWORD: 'gatewayConnect.messages.INVALID_TRUSTED_APP_PASSWORD',
	INVALID_IP: 'gatewayConnect.messages.INVALID_IP',
	INVALID_GATEWAY_IP: 'gatewayConnect.messages.INVALID_GATEWAY_IP',
	INVALID_PBX_IP: 'gatewayConnect.messages.INVALID_PBX_IP',
	TRUSTED_APP_DISABLED: 'gatewayConnect.messages.TRUSTED_APP_DISABLED',
	INVALID_TRUNK_GROUP: 'gatewayConnect.messages.INVALID_TRUNK_GROUP',
	LOST_CSTA_CONNECTION: 'gatewayConnect.messages.LOST_CSTA_CONNECTION',
	LOST_TRUNK_CONNECTION_PBX: 'gatewayConnect.messages.LOST_TRUNK_CONNECTION_PBX',
	LOST_TRUNK_CONNECTION_CLOUD: 'gatewayConnect.messages.LOST_TRUNK_CONNECTION_CLOUD',
	CANNOT_CONNECT_PBX: 'gatewayConnect.messages.CANNOT_CONNECT_PBX',
	SYSTEM_UPDATE_AVAILABLE: 'gatewayConnect.messages.SYSTEM_UPDATE_AVAILABLE',
	CHECKING_CONNECT: 'gatewayConnect.messages.CHECKING_CONNECT',
	ERR_CONNECT_NEW_GATEWAY: 'gatewayConnect.messages.ERR_CONNECT_NEW_GATEWAY',
	FAILED_REGISTER_PBX_MEDIA: 'gatewayConnect.messages.FAILED_REGISTER_PBX_MEDIA',
	FAILED_REGISTER_MEDIA: 'gatewayConnect.messages.FAILED_REGISTER_MEDIA',
	FAILED_REGISTER_PBX: 'gatewayConnect.messages.FAILED_REGISTER_PBX',
  ERR_RELINK: 'gatewayConnect.messages.ERR_RELINK',
  SUCCESS_RELINK: 'gatewayConnect.messages.SUCCESS_RELINK',
  ERR_PBX_MISSING_LINKS: 'gatewayConnect.messages.ERR_PBX_MISSING_LINKS'
};

export const pbxConnectError = {
	INVALID_TRUSTED_APP_PASSWORD: 'INVALID_TRUSTED_APP_PASSWORD',
	INVALID_IP: 'INVALID_IP',
	INVALID_GATEWAY_IP: 'INVALID_GATEWAY_IP',
	INVALID_PBX_IP: 'INVALID_PBX_IP',
	TRUSTED_APP_DISABLED: 'TRUSTED_APP_DISABLED',
	INVALID_TRUNK_GROUP: 'INVALID_TRUNK_GROUP',
};

export const pbxStatus = {
	UNKNOWN: 'unknown',
	UP: 'up',
	DOWN: 'down',
  MISSING: 'missing'
};

export const avatarMessage = {
	ERR_TYPE: 'avatar.ERR_TYPE',
	ERR_FILE_SIZE: 'avatar.ERR_FILE_SIZE',
	ERR_UP_FILE: 'avatar.ERR_UP_FILE'
};

export const emailValidationMessage = {
	VALIDATE_EMAIL: 'sendEmailsModal.VALIDATE_EMAIL'
};

export const userProfileMessage = {
	ERR_UPDATE_MOBILE: 'userProfile.ERR_UPDATE_MOBILE'
};

export const stepStatus = {
	FINISH_BUILD_CUSTOMER: 'stepStatus.FINISH_BUILD_CUSTOMER',
	CONFIG_GATEWAY: 'stepStatus.CONFIG_GATEWAY',
	ADD_PBX: 'stepStatus.ADD_PBX',
	CONNECT_PBX: 'stepStatus.CONNECT_PBX',
	SYNC_PBX: 'stepStatus.SYNC_PBX',
	CONFIG_OFFICELINK: 'stepStatus.CONFIG_OFFICELINK',
	OVERVIEW: 'stepStatus.OVERVIEW'
};

// tslint:disable-next-line:max-line-length
export const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const SUPPORTEMAIL_REGEXP = /cloudlink-support@mitel.com/i;

export const DEFAULT_GOOGLE_DNS_SERVERS = '8.8.8.8,8.8.4.4';

export const pbxTypes = {
	MIVO250: 'mivo250',
	MIVO400: 'mvo400',
	MIVB: 'mivb',
	MIVC: 'mivconnect',
	MXONE: 'mxone',
	MV5000: 'mv5000'
};

export const pbxSelectOptions = [
	{ name: 'preReq.pbxTypes.mivo250', value: pbxTypes.MIVO250 },
	{ name: 'preReq.pbxTypes.mivo400', value: pbxTypes.MIVO400 },
	{ name: 'preReq.pbxTypes.mv5000', value: pbxTypes.MV5000 },
  { name: 'preReq.pbxTypes.mxone', value: pbxTypes.MXONE },
	{ name: 'preReq.pbxTypes.mivb', value: pbxTypes.MIVB },
	{ name: 'preReq.pbxTypes.mivconnect', value: pbxTypes.MIVC, betaSupport: true }
];

export const pbxProducts = {
  [pbxTypes.MIVO250]: ['MiVoice Office 250', 'Mitel OfficeLink'],
  [pbxTypes.MIVO400]: ['MiVoice Office 400', 'Mitel Office'],
  [pbxTypes.MIVB]: ['MiVoice Business', 'Mitel Office'],
  [pbxTypes.MIVC]: ['MiVoice Connect', 'Mitel Office'],
  [pbxTypes.MXONE]: ['MX-ONE', 'Mitel Office'],
  [pbxTypes.MV5000]: ['MiVoice 5000', 'Mitel Office']
};

export const gatewayProducts = ['MiVoice Office 250', 'Mitel OfficeLink', 'MiVoice Office 400', 'Mitel Office', 'MiVoice Business', 'MiVoice Connect', 'MiVoice 5000', 'MX-ONE'];

export const platformTypes = {
	APPLIANCE: 'appliance',
	SMBC: 'smbc'
};

export const applicationNames = {
  OFFICELINK: 'OfficeLink',
  MOMA: 'Mitel MiVoice Office',
  MONE: 'Mitel One'
};

export const applicationNamesLong = {
  OFFICELINK: 'Mitel OfficeLink',
  MOMA: 'Mitel Office Mobile Application',
  MONE: 'Mitel One Mobile Application'
};

export const mitelOneLicenses = ["MitelOne_Basic","MitelOne_Essential", "MitelOne_Premier",	"MitelOne_Elite"];

export enum FormAlertType {
	ERROR = 'alert-danger',
	SUCCESS = 'alert-success'
};

export enum MessageType {
	NO_SUPPORT = 'no-support',
	NO_SUPPORT_UPDATED_STATUS = 'no-support-updated-status'
};

export enum PolicyEffects {
    'ExplicitAllow',
    'ExplicitDeny',
    'ImplicitAllow',
    'ImplicitDeny'
};

export enum FeatureFlags {
  ALLOW_MIVOICE_BUSINESS = 'allow-mivoice-business'
}