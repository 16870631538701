import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { toastMsgTypes } from '../shared/constants';

@Injectable()
export class AlertService {
    private alert = {
        type: null,
        message: null,
        data: null,
    };
    alertChanged = new Subject<any>();

    constructor(private analyticsSvc: AnalyticsService) {}

    setAlert(type: string, msg: string, data?: string) {
        // on any toast error send event to analytics
        if (type === toastMsgTypes.ERROR) {
            // consolidate the error 'There are no users with ID ### in account ###'
            let eventMsg: string = msg;
            if (eventMsg.startsWith('There are no users with ID')) {
                eventMsg = 'There are no users with ID ...';
            }
            this.analyticsSvc.postAMAEvent('TOAST_ERROR', type, eventMsg);
        }
        this.alert.type = type;
        this.alert.message = msg;
        this.alert.data = data;
        this.alertChanged.next(this.alert);
    }

    setAlertType(type: string) {
        this.alert.type = type;
        this.alertChanged.next(this.alert);
    }

    setAlertMessage(msg: string) {
        this.alert.message = msg;
        this.alertChanged.next(this.alert);
    }

    setAlertData(data: string) {
        this.alert.data = data;
        this.alertChanged.next(this.alert);     
    }

    getAlert() {
        return this.alert;
    }

    clearAlert() {
        this.alert.type = null;
        this.alert.message = null;
        this.alert.data = null;
        this.alertChanged.next(this.alert);
    }
}
