import { Injectable } from '@angular/core';

import { FeatureAvailabilityService } from './feature-availability.service';
import { MivbProvisioningService } from './mivb-provisioning.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {

  constructor(
    private featureAvailabilityService: FeatureAvailabilityService,
    private mivbProvisioningService: MivbProvisioningService
  ) { }

  syncForMIVB(accountId: string, siteId: string) {
    // feature flag check for MiVB
    if(this.featureAvailabilityService.checkMiVBFeatureFlag()) {
        this.mivbProvisioningService.startSyncForMivb(accountId, siteId).then(response => {
          console.log("sync response for MiVB is ", response);
        })
        .catch((err => console.error('failed to start MiVB sync', err)))
    }
  }
}
